import { createContext, PropsWithChildren, useState } from 'react'

import { UserProfileData } from '../../api-utils/service-requests/user-sessions'
import { SideMenu } from './SideMenu'

export type SideMenuContext = {
  userProfile: UserProfileData | null
  isSheetOpen: boolean
  setIsSheetOpen: (isSheetOpen: boolean) => void
}

export const SideMenuContext = createContext<SideMenuContext>({
  userProfile: null,
  isSheetOpen: false,
  setIsSheetOpen: () => ({}),
})

type Props = {
  userProfile?: UserProfileData | null
}

export const SideMenuProvider = ({ children, userProfile }: PropsWithChildren<Props>) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  return (
    <SideMenuContext.Provider
      value={{ userProfile: userProfile ?? null, isSheetOpen, setIsSheetOpen }}>
      <SideMenu userProfile={userProfile ?? null} />
      {children}
    </SideMenuContext.Provider>
  )
}
