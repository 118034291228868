import { PropsWithChildren } from 'react'

import { Heading } from '../../ui/Typography'

type Props = PropsWithChildren<{
  title?: string
  hasTopBorder?: boolean
  className?: string
}>

export const UserPageSection = (props: Props) => {
  const { title, hasTopBorder = true, className = '' } = props
  return (
    <div
      className={`flex flex-col border-0 border-gray-200 ${className} pt-2`}
      style={{
        borderTopWidth: hasTopBorder ? '8px' : '0',
      }}>
      <div className="container py-4">
        {props.title && (
          <Heading level={3} weight="semi-bold">
            {title}
          </Heading>
        )}
        <div>{props.children}</div>
      </div>
    </div>
  )
}
