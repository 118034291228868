import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { CSSProperties } from 'react'

type RgbColor = {
  r: number
  g: number
  b: number
}
export type DuoToneBaseColor = RgbColor | string

export type DuoToneIconProps = { baseColor: DuoToneBaseColor } & FontAwesomeIconProps

const hexColorPattern = /^#[A-Fa-f0-9]{6}$/

/**
 * Simple wrapper around FontAwesomeIcon that allows setting the primary and secondary colors
 * for duo-tone icons. Accepts either an rgb definition or a 6-character hex color string.
 */
export const DuoToneIcon = (props: DuoToneIconProps) => {
  const { baseColor, ...rest } = props
  let primaryColor = '#000'
  let secondaryColor = '#666'
  if (typeof baseColor === 'string' && baseColor.match(hexColorPattern)) {
    primaryColor = baseColor
    secondaryColor = `${baseColor}99`
  } else if (typeof baseColor === 'object') {
    const c = baseColor as RgbColor
    const rgba = (a: number) => `rgba(${c.r}, ${c.g}, ${c.b}, ${a})`
    primaryColor = rgba(1.0)
    secondaryColor = rgba(0.6)
  }
  return (
    <FontAwesomeIcon
      {...rest}
      style={
        {
          '--fa-primary-color': primaryColor,
          '--fa-secondary-color': secondaryColor,
        } as CSSProperties // fix unknown prop issue
      }
    />
  )
}
