import { useContext } from 'react'

import { UserProfileData } from '../../api-utils/service-requests/user-sessions'
import { _T, useTranslations } from '../../components/helpers'
import { SideMenuContext } from './context'

type UserProfilePresentation = {
  firstName: string
  fullName: string
  formalName: string
}

export const useUserProfilePresenter = (
  userProfile: UserProfileData | undefined | null
): UserProfilePresentation => {
  const tr = useTranslations(translations)

  let firstName: string
  let fullName: string
  let formalName: string

  const { displayName } = userProfile ?? {}
  if (displayName && displayName.length) {
    fullName = displayName
    const parts = displayName.split(' ')
    firstName = parts[0]
    const lastName = parts.slice(1).join(' ')
    formalName = `${firstName.charAt(0).toUpperCase()}. ${lastName}`
  } else {
    fullName = tr.missingName
    firstName = tr.missingName
    formalName = tr.missingName
  }

  return {
    fullName,
    firstName,
    formalName,
  }
}

const translations = _T({
  en: {
    missingName: 'Karma user',
  },
  sv: {
    missingName: 'Karma-användare',
  },
  no: {
    missingName: 'Karma-bruker',
  },
})

export const useSideMenu = () => {
  const context = useContext(SideMenuContext)
  if (context === undefined) {
    throw new Error('useUserProfileSheet() must be used within a UserProfileSheetProvider')
  }
  return context
}
