export const isDevDomain = (baseUrl?: string) => {
  if (baseUrl) {
    return baseUrl.includes('development')
  }
  return window.location.hostname.includes('development')
}

export const isLocalDevWithDomain = (baseUrl?: string) => {
  if (baseUrl) {
    return baseUrl.includes('local')
  }
  return window.location.hostname.includes('.local')
}

export const getDomainName = (useHostNameAsDomain = false) => {
  // NOTE(martin) For server side rendering we return undefined and let the cookie default to the domain of the request
  // NOTE(christoffer) We also return undefined if useHostNameAsDomain is set, rather than returning the host name itself. This is
  //   to get consistent results out of the browser default, which isn't super trivial to predict.
  //   Some browsers default to the domain without the leading dot, and some browsers default to the domain with the leading dot.
  //   E.g. `undefined` can mean "mystore.store.karma.life" and ".mystore.store.karma.life". For this reason we have to be
  //   consistent in sending `undefined` when we want the default regardless of being on the frontend or backend. If not, we
  //   risk ending up with two cookies with the same name (which, it turns out, has very poor support in `nookies` and `cookie`).
  if (typeof window === 'undefined' || useHostNameAsDomain) {
    return undefined
  }

  const hostName = window.location.hostname

  // For localhost dev we set it to such
  if (hostName === 'localhost') {
    return hostName
  }

  if (isLocalDevWithDomain()) {
    return '.store.karma.local'
  }

  if (isDevDomain()) {
    return '.store.development.karma.life'
  }
  // Return the last path of storefront subdomain
  return '.store.karma.life'
}

export const isDev = (baseUrl?: string) => {
  return isLocalDevWithDomain(baseUrl) || isDevDomain(baseUrl)
}
