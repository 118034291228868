import { UserProfileData } from '../../api-utils/service-requests/user-sessions'
import { Backdrop } from '../../components/backdrop'
import SideDrawer from '../../components/side-drawer'
import { useSideMenu } from './'
import { SideMenuContent } from './SideMenuContent'

type Props = {
  userProfile: UserProfileData | null
}

export const SideMenu = ({ userProfile }: Props) => {
  const sideMenu = useSideMenu()
  const portalId = 'user-profile-sheet-portal-root'
  const closeProfileSheet = () => {
    sideMenu.setIsSheetOpen(false)
  }
  const isOpen = sideMenu.isSheetOpen
  return (
    <>
      <Backdrop shouldShow={isOpen} onClickCallback={closeProfileSheet} />
      <SideDrawer portalId={portalId} isOpen={isOpen} onCloseClick={closeProfileSheet}>
        <SideMenuContent userProfile={userProfile} />
      </SideDrawer>
    </>
  )
}
