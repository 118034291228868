import { PropsWithChildren } from 'react'

import { Heading } from '../../ui/Typography'

type Props = PropsWithChildren<{
  className?: string
}>
export const UserPageHeader = (props: Props) => {
  const { children, className = '' } = props
  return (
    <Heading level={3} weight="medium" className={`container !text-[35px] ${className}`}>
      {children}
    </Heading>
  )
}
